import React from 'react';

function Footer() {
  return (
    <footer className="footer" style={{ backgroundColor: '#2F4F4F', position: 'fixed', bottom: '0px', width: '100%', height: '60px', lineHeight: '60px' }}>
      <div className="container text-center">
        <span style={{ color: '#E0FFFF' }}>
          Copyright &copy; {new Date().getFullYear()} Email Extractor. All Rights Reserved
        </span>
      </div>
    </footer>
  );
}

export default Footer;
