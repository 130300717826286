import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <nav className="navbar navbar-expand-lg" style={{ backgroundColor: '#2F4F4F' }}>
      <Link className="navbar-brand" to="/" style={{ color: '#E0FFFF' }}>Email Extractor</Link>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
        style={{ backgroundColor: '#E0FFFF', borderColor: '#E0FFFF', padding: '5px' }}>
        <span className="navbar-toggler-icon" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: 'transparent', height: '24px', width: '30px' }}>
          <span style={{
            display: 'block',
            width: '22px',
            height: '2px',
            backgroundColor: '#2F4F4F',
            margin: '2px 0'
          }}></span>
          <span style={{
            display: 'block',
            width: '22px',
            height: '2px',
            backgroundColor: '#2F4F4F',
            margin: '2px 0'
          }}></span>
          <span style={{
            display: 'block',
            width: '22px',
            height: '2px',
            backgroundColor: '#2F4F4F',
            margin: '2px 0'
          }}></span>
        </span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/about" style={{ color: '#E0FFFF' }}>About</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/contact" style={{ color: '#E0FFFF' }}>Contact</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/email-checker" style={{ color: '#E0FFFF' }}>Email Checker</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/email-verifier" style={{ color: '#E0FFFF' }}>Email Verifier</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Header;
