import React from 'react';

const ClearButton = () => {
  return (
    <button
      type="button"
      className="btn btn-lg"
      onClick={() => window.location.reload()}
      style={{
        backgroundColor: '#2F4F4F',   // Dark Slate Gray
        color: '#E0FFFF',             // Light Cyan
        borderColor: '#FF7F50',       // Coral
      }}
    >
      Clear
    </button>
  );
}

export default ClearButton;
